@font-face {
  font-family: "Avenir";
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/avenir-next-thin.woff?v=1666230063") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Avenir";
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Regular.otf?v=1662527890") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Avenir";
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Medium.woff?v=1662527889") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Avenir";
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Bold.woff?v=1666321597") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Avenir";
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Demi.woff?v=1662527889") format("woff");
  font-style: normal;
  font-weight: 600;
}