@use 'fonts.scss';
@use 'mixins.scss';

html,
body {
  position: relative;
  height: 100%;
  font-family: 'Avenir', sans-serif;
  background: white;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.ha-hidden {
  display: none !important;
}


.ha-fab-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
}

.ha-fab-close {
  fill: #1E3050;
  cursor: pointer;
  width: 16px;
  height: 16px;
  right: 8px;
  top: 8px;
  position: absolute;
}

.ha-fab {
  background-color: #959385;
  color: white;
  height: 52px;
  width: 52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 7px 8px -5px rgba(0, 0, 0, 0.2),
      0px 12px 17px 2px rgba(0, 0, 0, 0.14),
      0px 5px 22px 4px rgba(0, 0, 0, 0.12);

    svg {
      transform: rotate(-45deg);
    }
  }

  svg {
    height: 24px;
    width: 24px;
    fill: white;
    transition: all 0.2s ease-in-out;
  }
}

.ha-fab-content {
  background-color: white;
  position: absolute;
  bottom: 90px;
  right: 16px;
  border-radius: 12px;
  transition: all 0.1s ease-out;
  filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));

  .ha-fab-arrow {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 14px;
    bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(90deg);
      height: 26px;
      fill: white;
    }
  }
}

.ha-fab-content-hidden {
  opacity: 0;
  visibility: hidden;
  bottom: 80px;
}

.ha-config-wrapper {
  padding: 1rem;
  padding-top: 1.5rem;
  min-width: 256px;

  h2 {
    margin: 0px;
    margin-bottom: 8px;
  }

  h3 {
    font-weight: 400;
  }

}

.ha-configs-title {
  margin-bottom: 0px;
}

.ha-config-title {
  display: inline-block;
  margin-right: 10px;
}

.ha-config-select {
  display: inline-block;
}

.ha-graphComponentWrapper {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Avenir', sans-serif;
  }
}

.ha-propellerSelectorComponent {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 600px;
}

.ha-grid-line {
  stroke: #F1EFED;
}

.tick text {
  font-size: 1rem;
  fill: #635c49
}

.ha-x-axis {
  .tick {
    &:first-of-type {
      text-anchor: start;
    }

    &:last-of-type {
      text-anchor: end;
    }
  }
}

.ha-AvgSpeed {
  fill: #635c49;
  font-size: 14px;
  font-weight: 700;
}

.ha-X-AxisLabel {
  fill: #635c49;
  font-size: 14px;
  font-weight: 500;
}

.ha-speed {
  font-weight: 400;
  font-size: 14px;
}

.ha-line {
  stroke-width: 2px;
  stroke-linecap: round;
  fill: none;
  cursor: pointer;
  transition: filter 500ms ease-in-out;
}

.ha-graphWrapper {
  max-width: 600px;
  height: 400px;
  width: 100%;
}

.ha-Y-AxisLabel {
  fill: #635c49;
  font-size: 14px;
  text-anchor: middle;
}

.ha-FoilTime {
  fill: #635c49;
  font-weight: 500;
}

.ha-slider-popup-text {
  fill: white;
  font-size: 15px;
  font-weight: 500;
  text-anchor: middle;
}

.ha-slider-popup-rect {
  fill: #969180;
}

.ha-slider-x-axis-popup-text {
  fill: white;
  font-size: 16px;
  font-weight: 600;
  text-anchor: middle;
}

.ha-slider-x-axis-popup-rect {
  fill: #8d8d8d
}

.ha-slider-line {
  stroke: #8D8D8D;
  stroke-width: 1px;
  stroke-dasharray: 4, 4;
}

.ha-slider-handle {
  cursor: move;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-radius: 32px;
    outline: auto 2px -webkit-focus-ring-color;
  }
}

.ha-panelWrapper {
  display: flex;
  gap: 8px;
  justify-content: center;
  max-width: 600px;
  height: fit-content;
}


.ha-propellerSelector {
  max-width: 600px;
}

.ha-propellerSelectorWrapper {
  position: relative;
  background: #F1EFED;
  // border: 1px solid #969385;
  border-radius: 50px;
  margin-bottom: 16px;

  .ha-propellerGraphLineSample {
    width: 18px;
    height: 2px;
    margin-right: 10px;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 500px) {
      width: 16px;
      margin-bottom: 2px;
    }

    &.ha-TrueGlidePropLineSample {
      background-color: #635c49;
    }

    &.ha-PropAndGuardLineSample {
      background-color: #EB8B33;
    }

    &.ha-JetLineSample {
      background-color: #B0AEA5;
    }
  }

  p {
    margin: 0;
  }

  .ha-propellerSelectorOptions {
    position: relative;
    display: flex;
    z-index: 2;
  }

  .ha-propellerOption {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex: 1;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #635c49;
    padding: 12px;
    cursor: pointer;
    border-radius: 10000px;
    transition: all 0.2s ease-in-out;

    @media (max-width: 500px) {
      font-size: 12px;
      flex-direction: column;
      gap: 8px;

      .ha-propellerGraphLineSample {
        margin: 0px;
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.ha-selected {
      color: white;
    }

    &.ha-selected:hover {
      background: none;
    }

  }

  .ha-propellerSelectedOptionBackground {
    background: #969385;
    border-radius: 50px;
    height: calc(100% + 2px);
    width: calc(33.333% + 2px);
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1px;
    margin: 0;
    transition: left 0.3s ease-in-out;
    // border: 1px solid #969385;
  }
}

.ha-propellerSelectorTitle {
  font-weight: 500;
  font-size: 24px;
  color: #635c49;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 12px;

  @media screen and (max-width: 950px) {
    margin-top: 12px;
  }
}

.ha-propellerSelectorDescription {
  font-weight: 500;
  font-size: 16px;
  color: #635c49;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 16px;
}

.ha-graphComponentWrapperInner {
  display: flex;
  flex-direction: row;
  gap: 64px;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    gap: 16px;
  }
}

.ha-sidePanel {
  width: 100%;
  background: #F1EFED;
  border-radius: 40px;
  padding: 40px;
  max-width: 600px;

  @media (min-width: 951px) {
    max-width: 330px;
  }

  .ha-sidePanelTitle {
    font-weight: 500;
    font-size: 24px;
    color: #635c49;
    margin: 0;
    margin-bottom: 12px;
  }

  .ha-sidePanelDescription {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    color: #635c49;
    margin-bottom: 32px;
  }

  .ha-sidePanelOptionTitle {
    font-weight: 500;
    font-size: 16px;
    color: #635c49;
    margin: 0;
    margin-bottom: 8px;
  }

  .ha-sidePanelSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
    color: #635c49;
    margin-bottom: 24px;
    padding: 14px 16px;
    width: 100%;
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23000000' d='M7 10l5 5 5-5z'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    background-size: 24px 24px;
    cursor: pointer;
    font-weight: 400;
  }

  .ha-sidePanelSelectStatic {
    background-image: none;
    cursor: inherit;
    pointer-events: none;
  }
}


.ha-fliteCellSelectorWrapper {
  position: relative;
  background: white;
  // border: 1px solid #969385;
  border-radius: 50px;

  p {
    margin: 0;
  }

  .ha-fliteCellSelectorOptions {
    position: relative;
    display: flex;
    z-index: 2;
  }

  .ha-fliteCellOption {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex: 1;
    text-align: center;
    font-weight: 600;
    color: #635c49;
    padding: 12px;
    cursor: pointer;
    border-radius: 10000px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.ha-selected {
      color: white;
    }

    &.ha-selected:hover {
      background: none;
    }

  }

  .ha-fliteCellSelectedOptionBackground {
    background: #969385;
    border-radius: 50px;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1px;
    margin: 0;
    transition: left 0.3s ease-in-out;
    // border: 1px solid #969385;
  }
}

.ha-propellerSelectorGraph {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    flex-direction: column-reverse;

  }
}

.ha-graphComponentHeadWrapper {
  display: flex;
  flex-direction: column;
  background-color: #F1EFEE;
  padding: 80px 0px;
  align-items: center;
  justify-content: center;
}

.ha-graphComponentHead {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  color: #635c49;
}

.ha-graphComponentTitle {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 52px;
}

.ha-graphComponentDescriptionTitle {
  font-weight: 600;
  font-size: 19px;
  margin-bottom: 4px;
}

.ha-graphComponentDescription {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 14px;
}

.ha-graphComponentDescriptionLi {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 8px;
}