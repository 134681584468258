@font-face {
  font-family: Avenir;
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/avenir-next-thin.woff?v=1666230063") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Avenir;
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Regular.otf?v=1662527890") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Medium.woff?v=1662527889") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Bold.woff?v=1666321597") format("woff");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: Avenir;
  src: url("https://cdn.shopify.com/s/files/1/0278/0810/4513/files/AvenirNextLTPro-Demi.woff?v=1662527889") format("woff");
  font-style: normal;
  font-weight: 600;
}

html, body {
  height: 100%;
  color: #000;
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  position: relative;
}

.ha-hidden {
  display: none !important;
}

.ha-fab-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
}

.ha-fab-close {
  fill: #1e3050;
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.ha-fab {
  color: #fff;
  height: 52px;
  width: 52px;
  cursor: pointer;
  background-color: #959385;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 16px;
  transition: all .2s ease-in-out;
  display: flex;
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.ha-fab:hover {
  transform: scale(1.1);
  box-shadow: 0 7px 8px -5px #0003, 0 12px 17px 2px #00000024, 0 5px 22px 4px #0000001f;
}

.ha-fab:hover svg {
  transform: rotate(-45deg);
}

.ha-fab svg {
  height: 24px;
  width: 24px;
  fill: #fff;
  transition: all .2s ease-in-out;
}

.ha-fab-content {
  filter: drop-shadow(0 8px 10px #00000024) drop-shadow(0 3px 14px #0000001f);
  background-color: #fff;
  border-radius: 12px;
  transition: all .1s ease-out;
  position: absolute;
  bottom: 90px;
  right: 16px;
}

.ha-fab-content .ha-fab-arrow {
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -20px;
  right: 14px;
}

.ha-fab-content .ha-fab-arrow svg {
  height: 26px;
  fill: #fff;
  transform: rotate(90deg);
}

.ha-fab-content-hidden {
  opacity: 0;
  visibility: hidden;
  bottom: 80px;
}

.ha-config-wrapper {
  min-width: 256px;
  padding: 1.5rem 1rem 1rem;
}

.ha-config-wrapper h2 {
  margin: 0 0 8px;
}

.ha-config-wrapper h3 {
  font-weight: 400;
}

.ha-configs-title {
  margin-bottom: 0;
}

.ha-config-title {
  margin-right: 10px;
  display: inline-block;
}

.ha-config-select {
  display: inline-block;
}

.ha-graphComponentWrapper * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Avenir, sans-serif;
}

.ha-propellerSelectorComponent {
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.ha-grid-line {
  stroke: #f1efed;
}

.tick text {
  fill: #635c49;
  font-size: 1rem;
}

.ha-x-axis .tick:first-of-type {
  text-anchor: start;
}

.ha-x-axis .tick:last-of-type {
  text-anchor: end;
}

.ha-AvgSpeed {
  fill: #635c49;
  font-size: 14px;
  font-weight: 700;
}

.ha-X-AxisLabel {
  fill: #635c49;
  font-size: 14px;
  font-weight: 500;
}

.ha-speed {
  font-size: 14px;
  font-weight: 400;
}

.ha-line {
  stroke-width: 2px;
  stroke-linecap: round;
  fill: none;
  cursor: pointer;
  transition: filter .5s ease-in-out;
}

.ha-graphWrapper {
  max-width: 600px;
  height: 400px;
  width: 100%;
}

.ha-Y-AxisLabel {
  fill: #635c49;
  text-anchor: middle;
  font-size: 14px;
}

.ha-FoilTime {
  fill: #635c49;
  font-weight: 500;
}

.ha-slider-popup-text {
  fill: #fff;
  text-anchor: middle;
  font-size: 15px;
  font-weight: 500;
}

.ha-slider-popup-rect {
  fill: #969180;
}

.ha-slider-x-axis-popup-text {
  fill: #fff;
  text-anchor: middle;
  font-size: 16px;
  font-weight: 600;
}

.ha-slider-x-axis-popup-rect {
  fill: #8d8d8d;
}

.ha-slider-line {
  stroke: #8d8d8d;
  stroke-width: 1px;
  stroke-dasharray: 4 4;
}

.ha-slider-handle {
  cursor: move;
}

.ha-slider-handle:focus {
  outline: none;
}

.ha-slider-handle:focus-visible {
  outline: auto 2px -webkit-focus-ring-color;
  border-radius: 32px;
}

.ha-panelWrapper {
  max-width: 600px;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  gap: 8px;
  display: flex;
}

.ha-propellerSelector {
  max-width: 600px;
}

.ha-propellerSelectorWrapper {
  background: #f1efed;
  border-radius: 50px;
  margin-bottom: 16px;
  position: relative;
}

.ha-propellerSelectorWrapper .ha-propellerGraphLineSample {
  width: 18px;
  height: 2px;
  border-radius: 20px;
  margin-right: 10px;
  transition: all .2s ease-in-out;
}

@media (max-width: 500px) {
  .ha-propellerSelectorWrapper .ha-propellerGraphLineSample {
    width: 16px;
    margin-bottom: 2px;
  }
}

.ha-propellerSelectorWrapper .ha-propellerGraphLineSample.ha-TrueGlidePropLineSample {
  background-color: #635c49;
}

.ha-propellerSelectorWrapper .ha-propellerGraphLineSample.ha-PropAndGuardLineSample {
  background-color: #eb8b33;
}

.ha-propellerSelectorWrapper .ha-propellerGraphLineSample.ha-JetLineSample {
  background-color: #b0aea5;
}

.ha-propellerSelectorWrapper p {
  margin: 0;
}

.ha-propellerSelectorWrapper .ha-propellerSelectorOptions {
  z-index: 2;
  display: flex;
  position: relative;
}

.ha-propellerSelectorWrapper .ha-propellerOption {
  text-align: center;
  color: #635c49;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 10000px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  transition: all .2s ease-in-out;
  display: flex;
}

@media (max-width: 500px) {
  .ha-propellerSelectorWrapper .ha-propellerOption {
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
  }

  .ha-propellerSelectorWrapper .ha-propellerOption .ha-propellerGraphLineSample {
    margin: 0;
  }
}

.ha-propellerSelectorWrapper .ha-propellerOption:hover {
  background: #0000000d;
}

.ha-propellerSelectorWrapper .ha-propellerOption.ha-selected {
  color: #fff;
}

.ha-propellerSelectorWrapper .ha-propellerOption.ha-selected:hover {
  background: none;
}

.ha-propellerSelectorWrapper .ha-propellerSelectedOptionBackground {
  height: calc(100% + 2px);
  width: calc(33.333% + 2px);
  z-index: 1px;
  background: #969385;
  border-radius: 50px;
  margin: 0;
  transition: left .3s ease-in-out;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.ha-propellerSelectorTitle {
  color: #635c49;
  margin: 40px 0 12px;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 950px) {
  .ha-propellerSelectorTitle {
    margin-top: 12px;
  }
}

.ha-propellerSelectorDescription {
  color: #635c49;
  margin: 20px 0 16px;
  font-size: 16px;
  font-weight: 500;
}

.ha-graphComponentWrapperInner {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  padding: 16px;
  display: flex;
}

@media (max-width: 950px) {
  .ha-graphComponentWrapperInner {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.ha-sidePanel {
  width: 100%;
  max-width: 600px;
  background: #f1efed;
  border-radius: 40px;
  padding: 40px;
}

@media (min-width: 951px) {
  .ha-sidePanel {
    max-width: 330px;
  }
}

.ha-sidePanel .ha-sidePanelTitle {
  color: #635c49;
  margin: 0 0 12px;
  font-size: 24px;
  font-weight: 500;
}

.ha-sidePanel .ha-sidePanelDescription {
  color: #635c49;
  margin: 0 0 32px;
  font-size: 14px;
  font-weight: 400;
}

.ha-sidePanel .ha-sidePanelOptionTitle {
  color: #635c49;
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 500;
}

.ha-sidePanel .ha-sidePanelSelect {
  appearance: none;
  color: #635c49;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23000000' d='M7 10l5 5 5-5z'/></svg>");
  background-position: calc(100% - 16px);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  border-radius: 50px;
  margin-bottom: 24px;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
}

.ha-sidePanel .ha-sidePanelSelectStatic {
  cursor: inherit;
  pointer-events: none;
  background-image: none;
}

.ha-fliteCellSelectorWrapper {
  background: #fff;
  border-radius: 50px;
  position: relative;
}

.ha-fliteCellSelectorWrapper p {
  margin: 0;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellSelectorOptions {
  z-index: 2;
  display: flex;
  position: relative;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellOption {
  text-align: center;
  color: #635c49;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 10000px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 12px;
  font-weight: 600;
  transition: all .2s ease-in-out;
  display: flex;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellOption:hover {
  background: #0000000d;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellOption.ha-selected {
  color: #fff;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellOption.ha-selected:hover {
  background: none;
}

.ha-fliteCellSelectorWrapper .ha-fliteCellSelectedOptionBackground {
  height: calc(100% + 2px);
  z-index: 1px;
  background: #969385;
  border-radius: 50px;
  margin: 0;
  transition: left .3s ease-in-out;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.ha-propellerSelectorGraph {
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 950px) {
  .ha-propellerSelectorGraph {
    flex-direction: column-reverse;
  }
}

.ha-graphComponentHeadWrapper {
  background-color: #f1efee;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  display: flex;
}

.ha-graphComponentHead {
  max-width: 850px;
  color: #635c49;
  flex-direction: column;
  display: flex;
}

.ha-graphComponentTitle {
  margin-bottom: 52px;
  font-size: 28px;
  font-weight: 600;
}

.ha-graphComponentDescriptionTitle {
  margin-bottom: 4px;
  font-size: 19px;
  font-weight: 600;
}

.ha-graphComponentDescription {
  margin-bottom: 14px;
  font-size: 19px;
  font-weight: 400;
}

.ha-graphComponentDescriptionLi {
  margin-bottom: 8px;
  font-size: 19px;
  font-weight: 400;
}

/*# sourceMappingURL=index.22f507f4.css.map */
